<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ item && item.id ? $t("labels.edit") : $t("labels.add") }}
      <v-spacer></v-spacer>
      <v-btn color="success" @click="save" class="mr-2">
        {{ $t("labels.save") }}
      </v-btn>
      <v-btn color="error" outlined @click="close">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="item.supplier_code"
            class="c-input-small"
            outlined
            dense
            hide-details
            :label="$t('labels.supplier_code')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <input-number
            v-model.number="item.payment_value"
            type="number"
            class="c-input-small"
            outlined
            dense
            hide-details
            :label="$t('labels.payment_value')"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-autocomplete
            v-model="item.payment_method"
            :items="paymentMethodOptions"
            class="c-input-small"
            outlined
            dense
            hide-details
            :label="$t('labels.payment_method')"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="item.payment_code"
            class="c-input-small"
            outlined
            dense
            hide-details
            :label="$t('labels.payment_code')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-file-input
            accept="image/*"
            v-model="files"
            @change="onInputFileChange"
            append-icon="mdi-paperclip"
            prepend-icon=""
            dense
            outlined
            hide-details
            multiple
            class="c-input-small"
            :label="$t('labels.payment_image')"
          ></v-file-input>
        </v-col>
        <v-col cols="12" sm="6">
          <date-picker-model
            v-model="item.payment_date"
            :label="$t('labels.payment_date')"
            custom-class="c-input-small input-disabled-bold"
            outlined
            dense
            hide-details
            :single-line="false"
          ></date-picker-model>
        </v-col>
        <v-col cols="12" sm="12">
          <v-textarea
            v-model="item.note"
            class="c-input-small"
            outlined
            dense
            hide-details
            :label="$t('labels.note')"
            rows="2"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { compress } from "image-conversion";
import { httpClient } from "@/libs/http";
import moment from "moment";

export default {
  name: "PaymentCreate",
  components: {},
  props: {
    selectItem: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    files: null,
    isLoading: false,
    item: {
      supplier_code: null,
      payment_value: "",
      payment_method: null,
      payment_code: null,
      payment_date: null,
      payment_image: null,
      note: null,
    },
    paymentMethodOptions: [],
  }),
  computed: {},
  created() {
    const paymentMethodOptions = [
      {
        value: 1,
        text: this.$t("labels.payment_cash"),
      },
      {
        value: 2,
        text: this.$t("labels.payment_transfer"),
      },
    ];
    this.paymentMethodOptions = [...paymentMethodOptions];
    this.resetInput();
  },
  mounted() {},
  methods: {
    close() {
      this.$emit("close", true);
    },
    resetInput() {
      const item = {
        supplier_code: null,
        payment_value: "",
        payment_method: null,
        payment_code: null,
        payment_date: null,
        payment_image: null,
        note: null,
      };
      this.item = { ...item };
      if (this.selectItem && this.selectItem.id) {
        this.item = { ...this.item, ...this.selectItem };
      }
      this.files = null;
    },
    async onInputFileChange(files) {
      const fileUrls = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file) {
          const url = await this.uploadFile(file);
          fileUrls.push(url);
        }
      }
      this.item = { ...this.item, payment_image: fileUrls.join(";") };
    },
    async uploadFile(file) {
      const resBlob = await compress(file, {
        quality: 0.85,
        type: "image/jpeg",
      });
      const newFile = new File(
        [resBlob],
        `${moment().unix()}_${this.generateRandomString(5)}.jpg`,
        { type: "image/jpeg" }
      );
      let fd = new FormData();
      fd.append("file", newFile);
      const { data } = await httpClient.post(`/upload-image`, fd);
      return data;
    },
    async save() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }

      this.isLoading = true;

      try {
        await httpClient.post("/payment-history-save", this.item);
        this.isLoading = false;
        const msg = this.item.id
          ? this.$t("messages.update_success")
          : this.$t("messages.create_success");
        this.$vToastify.success(msg);
        this.close();
        /* if (this.item.id) {
                    this.close()
                } else {
                    this.resetInput()
                } */
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>

<style scoped></style>
